import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TarotPage from "./tarot";
import AstrologyPage from "./astrology";
import "./App.css";
import * as zenith from "./assets/zenith.png";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tarot" element={<TarotPage />} />
          <Route path="/astrology" element={<AstrologyPage />} />
        </Routes>
      </div>
    </Router>
  );
}

const Home = () => {
  return (
    <div className="container">
      <div className="mainContainer">
        <header>
          <h2 className="azTitle">Welcome to Ask Zenith</h2>
        </header>
        <p className="azDescription">
          In Astrology, the zenith is the highest point in the sky. It
          represents the peak of the celestial sphere and is a symbol of
          reaching new heights. At Ask Zenith, we aim to provide you with the
          tools and resources to reach your zenith.
        </p>
        <img src={zenith.default} alt="Zenith" className="azImage" />
        <p className="azDescription">
          Explore our tarot reading and astrology application on the app store
          soon. Ask Zenith unlocks insights about your life's journey. Zenith is
          our friendly guide that can provide you with a unique perspective on
          your path.
        </p>
        <nav className="navContainer">
          <ul>
            <li id="tarot">
              <Link className="navLink" to="/tarot">
                Tarot Readings
              </Link>
            </li>
            <li id="astrology">
              <Link className="navLink" to="/astrology">
                Astrology
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default App;
