import React from "react";
import { Link } from "react-router-dom";
import * as tarot from "./assets/tarot.png";

const TarotPage = () => {
  return (
    <div className="container">
      <div className="mainContainer">
        <header>
          <h2 className="tarotTitle">Tarot Readings</h2>
        </header>
        <p className="tarotDescription">
          Discover insights and guidance through our a tarot reading from
          Zenith. Let the cards illuminate your path and provide clarity on your
          journey.
        </p>
        <img src={tarot.default} alt="Tarot" className="tarotImage" />
        <Link to="/" className="navLinkHome">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default TarotPage;
