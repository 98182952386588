import React from "react";
import { Link } from "react-router-dom";
import * as horoscope from "./assets/horoscope.png";

const AstrologyPage = () => {
  return (
    <div className="container">
      <div className="mainContainer">
        <header>
          <h2 className="astrologyTitle">Astrology</h2>
        </header>
        <p className="astrologyDescription">
          Unlock the secrets of the stars with Zenith. Each month, Zenith will
          provide each zodiac sign with a personalized horoscope. An
          astrological weather report is also posted for major events such as
          eclipses and retrogrades.
        </p>
        <img
          src={horoscope.default}
          alt="Astrology"
          className="astrologyImage"
        />
        <Link to="/" className="navLinkHome">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default AstrologyPage;
